import type { Analytics } from 'firebase/analytics';
import { getAnalytics } from 'firebase/analytics';
import type { FirebaseApp } from 'firebase/app';
import { PropsWithChildren, useContext, useEffect, useState } from 'react';
import { AnalyticsSdkContext, useFirebaseApp } from 'reactfire';

export function LazyAnalyticsProvider(
  props: PropsWithChildren<{ firebaseApp: FirebaseApp }>
) {
  const firebaseApp = useFirebaseApp();
  const [analytics, setAnalytics] = useState<Analytics | undefined>(undefined);
  useEffect(() => {
    setAnalytics(getAnalytics(firebaseApp));
  }, [firebaseApp]);

  return (
    <AnalyticsSdkContext.Provider value={analytics}>
      {props.children || ''}
    </AnalyticsSdkContext.Provider>
  );
}

export function useLazyAnalytics(): Analytics | undefined {
  return useContext<Analytics | undefined>(AnalyticsSdkContext);
}
