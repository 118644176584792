const { resolve } = require('path');

/**
 * @type {import('next-i18next').UserConfig}
 **/
const i18nConfig = {
  i18n: {
    locales: ['en', 'ko', 'vi', 'ja'],
    defaultLocale: 'en',
  },
  react: { useSuspense: false },
  defaultNS: 'common',
  localePath: resolve('./apps/hitchhicar/public/locales'),
};

module.exports = i18nConfig;
