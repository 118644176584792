import { getFirebaseConfig } from '@/common/config';
import FirebaseProvider from '@/components/firebase-provider';
import Logo from '@/public/images/logo.svg';
import { Box } from '@mui/material';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import App, { AppProps } from 'next/app';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { useEffect, useRef, useState } from 'react';
import i18nConfig from '../next-i18next.config';
import DEFAULT_SEO from '../next-seo.config';
import './styles.css';

function CustomApp({ Component, pageProps }: AppProps) {
  const firebaseConfig = getFirebaseConfig();
  const router = useRouter();

  const ref = useRef(null);
  const [isTop, setIsTop] = useState(true);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <DefaultSeo {...DEFAULT_SEO} />
      <meta
        name="viewport"
        content="initial-scale=1.0,user-scalable=no,width=device-width"
      />
      {/* <Script id="facebook-pixel" async>
        {`
          !function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window, document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '615719687082385');
          fbq('track', 'PageView');
          `}
      </Script> */}
      <Script
        async
        strategy="afterInteractive"
        src="https://www.googletagmanager.com/gtag/js?id=AW-11183938705"
      ></Script>
      <Script id="google-analytics" strategy="afterInteractive">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
        
          gtag('config', 'AW-11183938705');
        `}
      </Script>
      <FirebaseProvider firebaseConfig={firebaseConfig}>
        <main className="app">
          {!router.pathname.includes('/qr') && (
            <Box
              ref={ref}
              onClick={() => {
                router.push('/');
              }}
              sx={{
                padding: '16px',
                display: 'flex',
                height: '56px',
                top: 0,
                position: 'fixed',
                width: '100%',
                justifyContent: 'center',
                transition: 'background-color 0.3s ease',
                backgroundColor: router.pathname.includes('/p/')
                  ? 'white'
                  : !isTop
                  ? 'white'
                  : '#EFF2F4',
                zIndex: 1200,
              }}
            >
              <Image
                src={Logo}
                alt="logo"
                style={{ height: '24px', cursor: 'pointer' }}
              />
            </Box>
          )}
          <Component {...pageProps} />
        </main>
      </FirebaseProvider>
    </>
  );
}

export default appWithTranslation(CustomApp, i18nConfig);

CustomApp.getInitialProps = async (appContext) => {
  const appProps = await App.getInitialProps(appContext);
  const {
    ctx: { req },
  } = appContext;

  if (req?.url.startsWith('/_next')) {
    return;
  }
  return { ...appProps };
};
