import { AuthProvider, FirebaseAppProvider, useFirebaseApp } from 'reactfire';
import type { FirebaseOptions } from 'firebase/app';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { getAuth } from 'firebase/auth';
import {
  LazyAnalyticsProvider,
  useLazyAnalytics as _useLazyAnalytics,
} from '@/components/firebase-provider/analytics';

export interface FirebaseProviderProps {
  firebaseConfig?: FirebaseOptions;
}

function SDKProvider({ children }: { children?: ReactNode | undefined }) {
  const firebaseApp = useFirebaseApp();

  return (
    <LazyAnalyticsProvider firebaseApp={firebaseApp}>
      <AuthProvider sdk={getAuth(firebaseApp)}>{children || ''}</AuthProvider>
    </LazyAnalyticsProvider>
  );
}

export const FirebaseProvider: FC<PropsWithChildren<FirebaseProviderProps>> = ({
  children,
  firebaseConfig,
}: PropsWithChildren<FirebaseProviderProps>) => {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SDKProvider>{children || ''}</SDKProvider>
    </FirebaseAppProvider>
  );
};

export default FirebaseProvider;

export const useLazyAnalytics = _useLazyAnalytics;
