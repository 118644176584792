import { FirebaseOptions } from 'firebase/app';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export function getEnv(): string {
  return publicRuntimeConfig.env;
}

export function getAPIBasePath(): string {
  return publicRuntimeConfig.apiBasePath;
}

export function getFirebaseConfig(): FirebaseOptions {
  return publicRuntimeConfig.firebaseConfig;
}

export function getAuth0Config() {
  return publicRuntimeConfig.auth0;
}

export function getBasePath() {
  return publicRuntimeConfig.basePath;
}

export function getPlaystoreID() {
  return publicRuntimeConfig.playStore;
}
