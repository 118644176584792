import { DefaultSeoProps } from 'next-seo';

const DEFAULT_SEO: DefaultSeoProps = {
  title: 'hitchicar | 히치카',
  description: '차량에 관련된 정보를 한번에!',
  openGraph: {
    type: 'website',
    locale: 'ko_KR',
    url: 'https://hitchicar.com',
    title: 'hitchicar | 히치카',
    siteName: 'hitchicar',
    images: [
      {
        url: '/images/main_thumb.png',
        width: 1200,
        height: 630,
        alt: 'main_thumb',
      },
    ],
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/images/favicon.svg',
    },
  ],
};

export default DEFAULT_SEO;
